const cf7FloatingLabels = function () {

  console.log('CF7 Floating Labels loaded!')
	const textinputs = document.querySelectorAll('.wpcf7-text, .wpcf7-textarea, .wpcf7-quiz, .wpcf7-number');
	var is_quiz=[], label=[];

	for (let i = 0; i < textinputs.length; i++) {

		let field_name;

		if (textinputs[i].classList.contains('wpcf7-text') || textinputs[i].classList.contains('wpcf7-textarea') || textinputs[i].classList.contains('wpcf7-number')) {

			field_name = textinputs[i].name;

			label[i] = document.querySelector("label[for='" + field_name + "']")
			is_quiz[i] = false;

		} else if (textinputs[i].classList.contains('wpcf7-quiz')) {

			label[i] = textinputs[i].closest('span').querySelector('.wpcf7-quiz-label');
			textinputs[i].classList.add('uk-input');
			is_quiz[i] = true;

		}


		if ((textinputs[i].value === '' || textinputs[i].getAttribute('placeholder')) && label[i] != undefined){

			label[i].classList.remove("ds-has-focus");

		} else if (textinputs[i].value !== '' && label[i] != undefined ) {

			label[i].classList.add("ds-has-focus");

		}

		textinputs[i].addEventListener('input', (e) => {
			if (textinputs[i].value != '' || textinputs[i] === document.activeElement) {
				label[i].classList.add("ds-has-focus");
			} else {
				label[i].classList.remove("ds-has-focus");
			}
		})

		textinputs[i].addEventListener('focus', (e) => {
			label[i].classList.add("ds-has-focus");
		})


		textinputs[i].addEventListener('focusout', (e) => {
			if (textinputs[i].value === '' && textinputs[i] !== document.activeElement) {
				label[i].classList.remove("ds-has-focus");
			}
		})

	}

};

cf7FloatingLabels();


document.addEventListener('wpcf7mailsent', function (event) {
	cf7FloatingLabels();
}, false );
