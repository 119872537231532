const totop = () => {

  console.log('totop.js ready!');
  let scrollPosition = window.scrollY;
  const totop = document.querySelector(".ds-totop");

  if (totop == null) return

  //hide
  totop.display = 'none';

  //on scrolling
  window.addEventListener('scroll', function (e) {

    scrollPosition = window.scrollY;

    if (scrollPosition >= 100) {
      totop.classList.add("is-visible");
    } else {
      totop.classList.remove("is-visible");
    }
  });

}
totop();


