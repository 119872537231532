import UIkit from 'uikit'

const navtoggle = document.getElementById('hamburger__nav-toggle');
let targetID;
let navcloseOlToggle;
let navOverlay;

if (navtoggle) {
  targetID = navtoggle.getAttribute('aria-controls');
  navcloseOlToggle = document.querySelectorAll('[id*=' + targetID + '] .hamburger');
  navOverlay = document.getElementById(targetID);
}

function hamburgerActiveToggle() {
  if (navcloseOlToggle) {
    navcloseOlToggle.forEach(function(elem) {
      elem.classList.toggle('is-active')
      elem.addEventListener('click', function (e) {
        this.classList.remove('is-active');
        if (navtoggle) {
          navtoggle.classList.remove('is-active');
        }
      })
    });
  }

  if (navtoggle) {
    navtoggle.addEventListener('click', function (e) {
      this.classList.toggle('is-active');
      if (navcloseOlToggle) {
        navcloseOlToggle.forEach(elem => elem.classList.toggle('is-active'));
      }
    })
  }
}

hamburgerActiveToggle();


// Add this function to handle window resize
function handleResize() {

    UIkit.modal(navOverlay).hide();
    if (navtoggle) {
      navtoggle.classList.remove('is-active');
    }
    if (navcloseOlToggle) {
      navcloseOlToggle.forEach(toggle => {
        toggle.classList.remove('is-active');
      });
    }

}

// Add resize event listener
window.addEventListener('resize', handleResize);

/*-------------------------------------------*/
// OVERLAY CLOSE ON LINK

if (navOverlay) {
  const navOverlayItems = navOverlay.querySelectorAll('li.menu-item > a[href*="#"]');

  navOverlayItems.forEach(function (item) {
    item.addEventListener('click', function (e) {
      if (navtoggle) {
        navtoggle.classList.remove('is-active');
      }

      if (navcloseOlToggle) {
        navcloseOlToggle.forEach(toggle => {
          toggle.classList.remove('is-active');
        });
      }

      UIkit.modal(navOverlay).hide();
    });
  });

  // remove active class from hamburger if overlay hides
  UIkit.util.on(document, 'hide', '.uk-modal', function() {
    if (navtoggle) {
      navtoggle.classList.remove('is-active');
    }
  });

  // Call handleResize on initial load
  handleResize();
}
