function stickyHeader() {
	console.log("stickyHeader ready!");
	const body = document.body;
	const scrollUp = "ds-scroll-up";
	const scrollDown = "ds-scroll-down";
	let lastScroll = 0;

	function handleScroll() {
    const pageHeader = document.querySelector(".ds-page-header");
    const headerHeight = pageHeader.offsetHeight;
		if (!pageHeader) return;
		const currentScroll = window.scrollY;
		// const headerHeight = pageHeader.offsetHeight;

		setRootVarHeaderHeight()

		// Reset styles when at the top of the page
		if (currentScroll <= 0) {
      body.classList.remove(scrollUp);
			pageHeader.classList.remove("ds-header-sticky");
			return;
    }

    if (currentScroll > headerHeight) {
			pageHeader.classList.add("ds-header-sticky");
			body.classList.add(scrollDown);
    }

		// Add/remove scroll direction classes and adjust .ds-header-sub top style
		if (currentScroll > lastScroll) { // Scrolling down
			if (!body.classList.contains(scrollDown)) {
				body.classList.remove(scrollUp);
				body.classList.add(scrollDown);
				// setRootVarHeaderHeight('0px');
				setRootVarHeaderHeight();
			}
		} else if (currentScroll < lastScroll) { // Scrolling up
			if (body.classList.contains(scrollDown)) {

				body.classList.remove(scrollDown);
				body.classList.add(scrollUp);

				setRootVarHeaderHeight();
			}
		}

		lastScroll = currentScroll;
	}


	function setRootVarHeaderHeight() {
		let headerHeight = document.querySelector(".ds-page-header").offsetHeight;
		document.documentElement.style.setProperty('--page-header-height', `${headerHeight}px`);
	}

	// Initial check on load
	window.addEventListener("load", handleScroll, false);

	// Handle scroll with requestAnimationFrame for performance
	window.addEventListener('scroll', () => {
		requestAnimationFrame(handleScroll);
	});
}

stickyHeader();
