// get styles
import './sass/main.scss';

// get scripts
import UIkit from 'uikit';

// Dynamically import the UIkit Icons
import('uikit/dist/js/uikit-icons').then(Icons => {
  // loads the Icon plugin
  UIkit.use(Icons.default);
});

// Ensure UIkit is available globally if needed
window.UIkit = UIkit;
window.UIkit.util = UIkit.util;

import './js/scripts.js';
