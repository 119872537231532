const validMessage = function (message) {
	UIkit.notification(message, { status: 'success', timeout: 3600 });
};

const errorMessage = function (message) {
	UIkit.notification(message, { status: 'warning', timeout: 3600 });
};

const cf7Events = function () {
	console.log("cf7Events.js ready!");

	document.querySelectorAll(".wpcf7").forEach(function(form) {
			form.addEventListener("wpcf7invalid", function (event) {
					hideCf7ResponseAndNotify(form, errorMessage);
			}, false);

			form.addEventListener("wpcf7mailfailed", function (event) {
					hideCf7ResponseAndNotify(form, errorMessage);
			}, false);

			form.addEventListener("wpcf7mailsent", function (event) {
					hideCf7ResponseAndNotify(form, validMessage);
			}, false);
	});
};

const hideCf7ResponseAndNotify = function(form, notifyFunction) {
	const messageOutput = form.querySelector(".wpcf7-response-output");
	// Hide CF7 response div
	messageOutput.style.display = "none";
	// Delay while waiting for text render
	setTimeout(function () {
			let message = messageOutput.innerText.trim();
			if (message) {
					notifyFunction(message);
			}
	}, 50);
};

cf7Events();
