console.log('TOCSmartMenu.js ready!');

class TOCSmartMenu extends HTMLElement {

  constructor() {
    super();
    this.contentSelector = this.getAttribute('content-selector') || '#contentStart';
    this.headingTags = this.getAttribute('heading-tags') ? this.getAttribute('heading-tags').split(',') : ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'];
    this.title = this.getAttribute('title') || 'Table of Contents'; // Default title
    this.tocClass = 'tocmenu'; // This class will be used to mark the TOC container and exclude it
  }

    connectedCallback() {
        setTimeout(() => {
            this.calculateScrollOffset();
    this.renderTOC();
          }, 100);

  }

  generateIdFromText(text) {
    // Prefix IDs with a string (e.g., 'heading-') to ensure they don't start with a number
    return 'heading-' + text.trim().toLowerCase().replace(/\s+/g, '-').replace(/^-/, '');
  }

  generateIdFromText(text) {
    // A simple hash function to convert text to a unique hash
    let hash = 0, i, chr;
    for (i = 0; i < text.length; i++) {
      chr = text.charCodeAt(i);
      hash = ((hash << 5) - hash) + chr;
      hash |= 0; // Convert to 32bit integer
    }
    // Ensure the hash is positive and prefix with 'id'
    return 'id' + Math.abs(hash);
  }

  getHeadingStructure() {
    const postContent = document.querySelector(this.contentSelector);
    if (!postContent) return [];

    const exclusions = ':not(.' + this.tocClass + ' *)'; // Excludes headings that are descendants of the TOC
    const headingsSelector = this.headingTags.map(tag => tag + exclusions).join(', '); // Apply the exclusion to each heading tag
    const headings = postContent.querySelectorAll(headingsSelector);

    return Array.from(headings).map(heading => {
      if (!heading.id) {
        heading.id = this.generateIdFromText(heading.textContent);
      }
      return {
        id: heading.id,
        text: heading.textContent,
        level: parseInt(heading.tagName.substring(1)),
      };
    });
  }


  calculateScrollOffset() {
    const htmlElement = document.documentElement;
    const headerHeight = htmlElement.style.getPropertyValue('--page-header-height') || '0px';
    this.scrollOffset = parseInt(headerHeight, 10) || 0;
  }


  renderTOC() {
    const headings = this.getHeadingStructure();
    if (!headings.length) return;

    let minLevel = Math.min(...headings.map(h => h.level));
    let tocHTML = `<div class="${this.tocClass}__title">${this.title}</div><ol class="${this.tocClass}__items uk-list uk-list-decimal">`;
    let openLists = [minLevel - 1];  // Start with a level lower than minLevel

    headings.forEach((heading) => {
      const { id, text, level } = heading;

      // Close lists until we reach a level lower than the current heading
      while (openLists.length > 1 && openLists[openLists.length - 1] >= level) {
        tocHTML += '</ol></li>';
        openLists.pop();
      }

      // Open new lists if there's a gap in levels
      while (openLists[openLists.length - 1] < level - 1) {
        tocHTML += `<li><ol class="${this.tocClass}__items uk-list uk-list-decimal">`;
        openLists.push(openLists[openLists.length - 1] + 1);
      }

      // Add the current item
      if (level > openLists[openLists.length - 1]) {
        tocHTML += `<li class="${this.tocClass}__item"><a class="${this.tocClass}__link" href="#${id}" uk-scroll="offset: ${this.scrollOffset}">${text}</a><ol class="${this.tocClass}__items uk-list uk-list-decimal">`;
        openLists.push(level);
      } else {
        tocHTML += `<li class="${this.tocClass}__item"><a class="${this.tocClass}__link" href="#${id}" uk-scroll="offset: ${this.scrollOffset}">${text}</a></li>`;
      }
    });

    // Close all remaining opened lists
    while (openLists.length > 1) {
      tocHTML += '</ol></li>';
      openLists.pop();
    }

    tocHTML += '</ol>';
    this.innerHTML = tocHTML;
  }


}

customElements.define('toc-smart-menu', TOCSmartMenu);
