import './modules/stickyHeader.js'
// import './modules/iframeHeight.js'
// import './modules/thumbnavSlider.js'
import './modules/cf7Events.js'
import './modules/cf7FloatingLabel.js'
import './modules/hamburgerActiveToggle.js'
// import './modules/lottieInviewAnimation.js'
import './modules/totop.js'
// import './modules/tabsHighlighting.js'
// import './modules/textSlideAnimation.js'
// import './modules/tocNavScrollspy.js'
import './modules/tocSmartMenu.js'


document.addEventListener("DOMContentLoaded", function () {
  // Handler when the DOM is fully loaded
  console.log('custom js executed...');


  UIkit.util.on(document, 'beforeshow', '.uk-drop.ds-drop-sidebar-menu', function() {
    document.body.classList.add('overlay-active');
  });

  UIkit.util.on(document, 'beforehide', '.uk-drop.ds-drop-sidebar-menu', function() {
    document.body.classList.remove('overlay-active');
  });


  // Select all video elements
  const videos = document.querySelectorAll('video');

  // Filter videos that have controls="false" or controls="0"
  videos.forEach(video => {
    if (video.getAttribute('controls') === 'false' || video.getAttribute('controls') === '0') {
      video.removeAttribute('controls');
    }

    if (video.getAttribute('autoplay') === 'false' || video.getAttribute('autoplay') === '0') {
      video.pause();
    }

    if (video.getAttribute('muted') === 'false') {
      video.muted = false;
    }

  });


  // Define elements to be observed and the elements to change classes
  const blendLightElements = document.querySelectorAll('.ds-scroll-overlay-light');
  const blendDarkElements = document.querySelectorAll('.ds-scroll-overlay-dark');
  const darkSections = document.querySelectorAll('section.uk-light');

  // Function to safely toggle classes on a list of elements
  function toggleClasses(elements, className, add) {
    elements.forEach(element => {
        if (element) {
            if (add) {
                element.classList.add(className);
            } else {
                element.classList.remove(className);
            }
        }
    });
  }


  if (darkSections.length > 0) {
    // Create a new intersection observer to handle visibility changes
    const observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
            // Toggle 'ds-light-mode' class based on whether the target is intersecting
            const isIntersecting = entry.isIntersecting;
            toggleClasses(blendLightElements, 'ds-light-mode', isIntersecting);
            toggleClasses(blendDarkElements, 'ds-light-mode', isIntersecting);
        });
    }, {
        threshold: 0.5 // Adjust the threshold to 0.5 to trigger when half of the target is visible
    });

    // Start observing each dark section
    darkSections.forEach(section => observer.observe(section));
}


});

